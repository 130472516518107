.family-analytics-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }

  > .container {
    flex-grow: 1;
    overflow: auto;
    .empty {
      .family-analytics-icon {
        font-size: 150px;
      }
    }
  }
}
