@import (reference) "./colors.less";
@import (reference) "./variables.less";

@import "./reset.less";
@import "./antd.less";
@import "./asset-risk.less";
@import "./legacy.less";
@import "./bb.less";
@import "./columns.less";

a {
  font-weight: 600;
  font-size: @font-size-small;
}

.anchor {
  color: @color-primary;
  cursor: pointer;
  font-weight: 600;
  font-size: @font-size-small;
}

body {
  background-color: @color-app-background;
  font-family: @font-family;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}
