@import (reference) "../../../../../../../styles/less/variables.less";

.risk-simulation-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;

  .summary {
    display: flex;
    align-items: center;

    .simulation-info {
      .date-author {
        font-size: @font-size-small;
      }
    }

    button {
      margin-left: 12px;
    }
  }

  &__buttons {
    display: flex;
  }
}
