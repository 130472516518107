@import (reference) "../../../../../styles/less/colors.less";
@import (reference) "../../../../../styles/less/variables.less";

.battery-conductance {
  .chart {
    padding-top: 20px;
    padding-bottom: 20px;

    .chart-name {
      font-size: @font-size-medium;
      color: @color-gray-90;
      margin-bottom: 20px;
    }
  }

  &.container {
    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px;
      font-size: @font-size-base;

      .icon {
        color: @color-status-blue;
        margin-right: 10px;
      }
    }

    .failed {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px;
      font-size: @font-size-base;

      .icon {
        color: @color-status-red;
        margin-right: 10px;
      }
    }
  }
}
