@import (reference) "../../../../../styles/less/colors.less";

.bb.asset-risk-chart {
  .bb-chart .bb-chart-lines {
    .no-cursor-here() {
      cursor: default !important;
      pointer-events: none !important;
    }

    .invisible() {
      .no-cursor-here();
      display: none !important;
      stroke: none !important;
      fill: none !important;
      opacity: 0 !important;
    }

    .area-base() {
      opacity: 1 !important;
      .no-cursor-here();
    }

    .bb-target-risk-low-stack {
      .bb-area {
        .area-base();
        fill: @color-risk-low !important;
      }
    }

    .bb-target-risk-med-stack {
      .bb-area {
        .area-base();
        fill: @color-risk-medium !important;
      }
    }

    .bb-target-risk-hig-stack {
      .bb-area {
        .area-base();
        fill: @color-risk-high !important;
      }
    }

    .bb-target-risk-low-stack,
    .bb-target-risk-med-stack,
    .bb-target-risk-hig-stack {
      .bb-lines {
        .bb-line {
          .invisible();
        }
      }

      .bb-circles {
        cursor: none !important;
        pointer-events: none !important;
        .bb-circle {
          .invisible();
        }
      }
    }

    .bb-target-importance-low,
    .bb-target-importance-high {
      .bb-line {
        opacity: 1 !important;
        .no-cursor-here();
      }

      .bb-circles {
        .bb-circle {
          .invisible();
        }
      }
    }

    [class^="bb-target-cluster"] {
      .bb-circles {
        .bb-circle {
          stroke: none;
          shape-rendering: geometricPrecision;
        }
      }
    }

    .bb-target-cluster-tier1 {
      .bb-circles {
        .bb-circle {
          opacity: 0.6 !important;
        }
      }
    }

    .bb-target-cluster-tier2 {
      .bb-circles {
        .bb-circle {
          opacity: 0.7 !important;
        }
      }
    }

    .bb-target-cluster-tier3 {
      .bb-circles {
        .bb-circle {
          opacity: 0.8 !important;
        }
      }
    }

    .bb-target-cluster-tier4 {
      .bb-circles {
        .bb-circle {
          opacity: 0.9 !important;
        }
      }
    }
  }

  .bb-chart {
    .bb-chart-circles {
      .bb-circles-other-assets {
        .bb-circle {
          fill: black !important;
          stroke: none !important;
          shape-rendering: geometricPrecision;
        }
      }

      .bb-circles-current-asset {
        .bb-circle {
          fill: white !important;
          opacity: 1 !important;
          shape-rendering: geometricPrecision;
          stroke: black !important;
        }
      }

      .bb-circles-prognostic {
        .bb-circle {
          stroke: @color-primary;
        }

        .bb-circle:nth-child(1) {
          opacity: 0.9 !important;
          stroke-width: 0.5px;
        }
        .bb-circle:nth-child(2) {
          opacity: 0.8 !important;
          stroke-width: 1px;
        }
        .bb-circle:nth-child(3) {
          opacity: 0.75 !important;
          stroke-width: 1.5px;
        }
        .bb-circle:nth-child(4) {
          opacity: 0.7 !important;
          stroke-width: 2.25px;
        }
        .bb-circle:nth-child(5) {
          opacity: 0.65 !important;
          stroke-width: 3px;
        }
      }
    }
  }

  .bb-grid .bb-xgrid-focus {
    visibility: hidden !important;
  }

  .bb-grid.bb-grid-lines {
    .bb-xgrid-lines,
    .bb-ygrid-lines {
      .bb-xgrid-line,
      .bb-ygrid-line {
        line {
          stroke: white;
          stroke-width: 1px;
          stroke-dasharray: 4;
          opacity: 0.7 !important;
        }
      }
    }
  }

  &.asset-risk-chart-grayscale {
    .bb-chart .bb-chart-lines {
      .bb-area-risk-low-stack {
        fill: @color-gray-30 !important;
      }

      .bb-area-risk-med-stack {
        fill: @color-gray-30 !important;
      }

      .bb-area-risk-hig-stack {
        fill: @color-gray-40 !important;
      }
    }
  }
}
