@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.ltc-operations-chart {
  .tick line {
    display: none;
  }

  .bb-ygrid {
    stroke: black !important;
    opacity: 0.1 !important;
    stroke-dasharray: 0;
  }

  .bb-ygrid-line {
    &.ltc-warning line {
      stroke-dasharray: 8;
      stroke: @color-status-yellow;
      stroke-width: 3;
    }

    &.ltc-alarm line {
      stroke-dasharray: 8;
      stroke: @color-status-orange;
      stroke-width: 3;
    }
  }

  .bb-regions rect.ltc-warning {
    fill: @color-status-yellow;
  }

  .bb-regions rect.ltc-alarm {
    fill: @color-status-orange;
  }

  .bb-chart-bars .bb-chart-bar path {
    stroke: @color-blue-60 !important;
    stroke-width: 1px;
    stroke-linecap: round;
    fill: @color-blue-50 !important;
    fill-opacity: 0.6;
  }
}

.ltc-operations-chart-legend {
  margin-top: -@spacing-medium;
}
