.asset-ribbon-back {
  float: left;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 100%;
  cursor: pointer;

  > span {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:before {
    margin: auto;
  }
}
