@import (reference) "../../../../../styles/less/colors.less";

.issues-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }

  .grid-container {
    height: 100%;
    overflow: hidden;

    > div {
      height: 100%;
      padding-bottom: 15px;
    }

    .container-content {
      .bootstrap-row {
        display: flex;
        justify-content: center;
      }

      a.anchor:not([href]):not([tabindex]) {
        color: @color-primary;
        text-decoration: none;
      }
      a.anchor:not([href]):not([tabindex]):hover,
      a.anchor:not([href]):not([tabindex]):focus {
        color: @color-primary-light;
        text-decoration: underline;
      }
    }

    .header {
      display: flex;
      align-items: center;

      .section-name {
        margin-right: 21px;
      }
    }
  }
}
