.recalculate-button {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &.calculating {
    pointer-events: none;

    .icon {
      animation: spin 1s infinite linear;
    }

    &.ant-btn-default {
      .icon {
        padding-right: 0px;
        margin-right: 5px;
      }
    }
  }
}
