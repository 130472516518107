@import (reference) "../../styles/less/colors.less";

@onlineData: rgb(32, 99, 151);
@offlineData: #000;

.chart-legend__item {
  cursor: pointer;
  + .chart-legend__item {
    margin-left: 15px;
  }
  &-box {
    margin-right: 5px;

    > span {
      display: inline-block;
    }
    &--filled {
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
    &--dotted {
      width: 10px;
      height: 4px;
      border-top: 2px dotted;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 600;
    color: @color-text-secondary-on-light;
  }
}

.gas-chart-legend-item {
  &-onlinedata {
    background: @onlineData;
  }
  &-offlinedata {
    background: @offlineData;
  }
}
