.dielectric-sf6-tabs {
  .dielectric {
    &-chart {
      &__wrapper {
        height: calc(100vh - 390px);
        width: 100%;
      }
      &__title {
        &-wrapper {
          padding-top: 0px;
        }
      }
    }
  }

  .dielectric-parameters-chart-tab {
    display: flex;
    align-items: center;
  }
}
