@import (reference) "../../../../../styles/less/colors.less";

.duval-triangles-note-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .duval-triangle-warning-icon {
    color: @color-status-yellow;
    margin-right: 12px;
  }
}
