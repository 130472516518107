.empty {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  .empty-icon {
    text-align: center;
    margin-bottom: 8px;
    opacity: 0.15;
  }

  .empty-description {
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0.75;
  }
}
