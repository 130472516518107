@import (reference) "../../../../styles/less/asset-risk.less";

.asset-risk-dashboard {
  .asset-risk-trend;

  &__row--center {
    position: absolute;
    height: calc(100% - 240px);
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .trend-chart {
    position: unset;
  }

  .resize-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;

    > div {
      width: 100%;
    }
  }
}
