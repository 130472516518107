@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";
@import "./Control.less";

.text-field-control {
  .control-label {
    margin-bottom: unit(@label-margin, px);

    > span + span {
      color: @color-status-red;
      margin-left: 1ch;
    }
  }

  label,
  input {
    display: block;
  }

  input,
  textarea {
    border: solid 1px @color-gray-40;
    font-size: @font-size-base;
    padding: 12px;
    width: 100%;
  }

  textarea {
    min-height: 110px;
  }

  textarea:focus {
    outline: none;
    border: solid 1px @color-primary;
  }

  .text-field-summary {
    display: flex;
    justify-content: space-between;
  }

  .text-field-counter {
    font-size: @font-size-base;
    white-space: nowrap;
  }

  input + .text-field-summary {
    margin-top: 6px;
  }

  textarea + .text-field-summary {
    margin-top: 1px;
  }

  &.error {
    input,
    textarea {
      border-bottom: solid 3px @color-status-red;
      padding-bottom: 10px;
    }
  }
}
