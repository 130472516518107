@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.aging-tab {
  height: calc(100vh - 230px);

  > div + div {
    margin-top: @spacing-xx-large;
  }

  .widget-message {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span.icon {
      padding: 0.5em;
      &.no-data::before {
        color: @color-status-blue;
      }
      &.error::before {
        color: @color-status-red;
      }
      font-size: @icon-font-size-x-large;
    }
    span {
      font-size: @font-size-base;
      color: @color-gray-100;
    }
  }

  .spinner {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
