@import (reference) "./colors.less";
@import (reference) "./variables.less";

.bb {
  svg {
    font-family: @font-family;
    font-size: @font-size-x-small;

    .bb-axis-x tspan,
    .bb-axis-y tspan,
    .bb-axis-y2 tspan,
    .bb-axis-x-label,
    .bb-axis-y-label {
      fill: @color-gray-70;
    }

    .bb-axis-x .tick text,
    .bb-axis-y .tick text,
    .bb-axis-y2 .tick text,
    .bb-axis-x-label,
    .bb-axis-y-label {
      font-size: 11px;
      font-weight: 600;
    }

    .bb-axis path,
    .bb-axis line {
      stroke: @color-gray-20 !important;
    }

    .bb-empty {
      fill: @color-gray-50;
      text-transform: uppercase;
    }

    .bb-grid {
      .bb-xgrids,
      .bb-ygrids {
        line {
          stroke: @color-gray-20;
        }
      }
    }

    .bb-legend-item,
    .bb-legend-item text {
      font-size: @font-size-base;
    }
  }
}
