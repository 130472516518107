@import (reference) "../../../../styles/less/colors.less";

.message-box {
  position: absolute;
  width: 100%;

  .right {
    top: 118px;
    right: 0;
  }

  .on-prem {
    width: 60%;
  }

  .azure {
    width: 70%;
  }

  .app-event:extend(.paragraph-white) {
    background-color: @color-status-blue;
    box-shadow: 1px 1px 6px @color-gray-80;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    min-height: 40px;
    padding: 0px 5px;
    width: 100%;

    > div {
      flex-grow: 1;
      margin: 8px;
      text-align: center;
    }

    > div.img {
      max-width: 20px;

      > .icon {
        display: block;
      }

      > .left.icon {
        border: solid 2px;
        border-radius: 50%;
        padding: 3px;
      }
    }

    > div.img > .close {
      cursor: pointer;
      font-size: 20px;
    }

    > div.img > img {
      font-size: 20px;
    }
  }

  .app-event.question:extend(.paragraph-on-light) {
    background-color: white;
    border-left: 50px solid white;
    padding: 10px;

    > div {
      text-align: left;
    }

    .img {
      position: absolute;
      left: 18px;
      color: @color-status-orange;
      font-size: 18pt;
      top: 35%;

      div {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    > div.buttons {
      > button + button {
        margin-top: 5px;
      }
    }
  }
}
