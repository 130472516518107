.issues-page,
.asset-risk-changes {
  .column.asset {
    padding: 10px;

    .id:extend(.link-label all) {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .details {
      font-size: 9pt;
      margin-top: 5px;
    }
  }
}
