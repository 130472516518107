@pilot-cell-color: #7c71a7;

@low-high-level-color: #ff7300;
@upper-lower-level-color: #ff7300;
@nominal-level-color: #7c71a7;

.battery-voltage-chart {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  .resize-wrapper {
    flex-grow: 1;
  }

  .bb-axis-y-label {
    transform: rotate(0);
    transform: translate(-4px, 26px);
  }

  .bb-axis-x-label {
    transform: translate(20px, 0px);
  }

  .bb-xgrid,
  .bb-ygrid {
    stroke: black;
    stroke-dasharray: 0;
  }

  .bb-ygrid-lines {
    .bb-ygrid-line {
      stroke-dasharray: 10 10;
      stroke-width: 2px;

      &.low-level,
      &.high-level {
        line {
          stroke: @low-high-level-color;
        }

        text {
          fill: @low-high-level-color;
        }
      }

      &.nominal-level {
        line {
          stroke: @nominal-level-color;
        }

        text {
          fill: @nominal-level-color;
        }
      }
    }
  }

  .bb-region {
    &.lower-limit {
      fill: @upper-lower-level-color;
      fill-opacity: 0.05;
    }

    &.upper-limit {
      fill: @upper-lower-level-color;
      fill-opacity: 0.05;
    }
  }

  .bb-chart .bb-chart-bars .bb-chart-bar .bb-bars .bb-bar.pilot-cell {
    stroke: @pilot-cell-color !important;
    stroke-width: 3px;
  }
}
