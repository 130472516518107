@import (reference) "../../styles/less/legacy.less";

.data-integration .model-config .model-parameters-mapping {
  * {
    box-sizing: border-box;
  }
  .grid-custom {
    .grid-body {
      .grid-row {
        .grid-cell > div {
          width: 100%;
        }
      }
    }

    .grid-header,
    .grid-body {
      display: flex;
      flex-direction: column-reverse;
      .grid-row {
        .grid-cell {
          max-width: 33%;
          width: 33%;
        }

        .grid-cell + .grid-cell {
          border-left: solid 1px @table-row-border-color;
        }
      }
    }
  }
}
