@import (reference) "../../../styles/less/colors.less";

.scatter-plot-widget {
  flex-grow: 1;
  height: calc(~"100vh - 220px");

  .resize-wrapper {
    height: 100%;
    .scatter-plot-chart {
      .tooltip {
        position: fixed;
      }
    }
  }

  &.error-message .icon {
    color: @color-status-red;
  }
}
