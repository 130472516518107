@import (reference) "../../../styles/less/colors.less";

.family-analytics-widget-container,
.scatter-plot-widget-container {
  height: 100%;
  .ant-col {
    height: 100%;
  }
}
.family-analytics-widget-container {
  .scatter-plot-widget-container {
    display: flex;
    flex-direction: column;

    .ant-row {
      overflow: auto;
    }
  }

  .header {
    display: flex;
    align-items: center;
    flex-grow: 1;
    .section-name {
      margin-right: 21px;
    }

    .family-analytics-subfilter {
      margin-right: 21px;
      width: 300px;
      min-width: 200px;
      border-radius: 0px;
      .ant-cascader-picker-label {
        width: 97%;
        padding-right: 20px;
      }
      > input {
        border-radius: 0px;
      }
    }
  }
}

.family-analytics-subfilter-popup {
  .ant-cascader-menu:nth-of-type(1) {
    min-width: 300px;
  }

  .ant-cascader-menu-item:not(.ant-cascader-menu-item-disabled) {
    height: 30px;
  }

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
    background-color: #f3f3f3;
  }

  .ant-cascader-menu-item:hover {
    background-color: @color-primary !important;
    color: white !important;
  }
}
