.family-contents,
.family-contents .data-grid {
  display: flex;
  flex-direction: column;
}
.family-contents {
  height: 100%;
  overflow: hidden;

  .section-name {
    span {
      white-space: normal;
    }
  }
  .data-grid {
    overflow: auto;
    .grid-body {
      overflow-y: auto;
      min-height: auto;
    }
  }
}
