.equation {
  .fraction {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.2em 0.4ex;
    text-align: center;
  }
  .fraction > span {
    display: block;
    padding-top: 0.15em;
  }
  .fraction span.fdn {
    border-top: thin solid black;
  }
  .fraction span.bar {
    display: none;
  }
}
