.col12 {
  margin: auto;
  max-width: 99.9%;
  width: 99.9%;
}

.col12vw {
  margin: auto;
  max-width: 1280px;
  min-width: 1280px;
  width: 1280px;
}

@media screen and (min-width: 1280px) {
  .col12vw {
    max-width: 99.9vw;
    min-width: 99.9vw;
    width: 99.9vw;
  }
}

@media screen and (min-width: 1440px) {
  .col12vw {
    max-width: 88.6vw;
    min-width: 88.6vw;
    width: 88.6vw;
  }
}

@media screen and (min-width: 1600px) {
  .col12vw {
    max-width: 79.9vw;
    min-width: 79.9vw;
    width: 79.9vw;
  }
}

@media screen and (min-width: 1920px) {
  .col12vw {
    max-width: 66.5vw;
    min-width: 66.5vw;
    width: 66.5vw;
  }
}

.col9 {
  max-width: 74.9%;
  width: 74.9%;
}

.col9vw {
  max-width: 960px;
  min-width: 960px;
  width: 960px;
}

@media screen and (min-width: 1280px) {
  .col9vw {
    max-width: 74.9vw;
    min-width: 74.9vw;
    width: 74.9vw;
  }
}

@media screen and (min-width: 1440px) {
  .col9vw {
    max-width: 66.5vw;
    min-width: 66.5vw;
    width: 66.5vw;
  }
}

@media screen and (min-width: 1600px) {
  .col9vw {
    max-width: 59.9vw;
    min-width: 59.9vw;
    width: 59.9vw;
  }
}

@media screen and (min-width: 1920px) {
  .col9vw {
    max-width: 49.9vw;
    min-width: 49.9vw;
    width: 49.9vw;
  }
}

.col8 {
  max-width: 66.5%;
  width: 66.5%;
}

.col8vw {
  max-width: 853px;
  min-width: 853px;
  width: 853px;
}

@media screen and (min-width: 1280px) {
  .col8vw {
    max-width: 66.5vw;
    min-width: 66.5vw;
    width: 66.5vw;
  }
}

@media screen and (min-width: 1440px) {
  .col8vw {
    max-width: 59.2vw;
    min-width: 59.2vw;
    width: 59.2vw;
  }
}

@media screen and (min-width: 1600px) {
  .col8vw {
    max-width: 53.3vw;
    min-width: 53.3vw;
    width: 53.3vw;
  }
}

@media screen and (min-width: 1920px) {
  .col8vw {
    max-width: 44.4vw;
    min-width: 44.4vw;
    width: 44.4vw;
  }
}

.col6 {
  max-width: 49.9%;
  width: 49.9%;
}

.col6vw {
  max-width: 640px;
  min-width: 640px;
  width: 640px;
}

@media screen and (min-width: 1280px) {
  .col6vw {
    max-width: 49.9vw;
    min-width: 49.9vw;
    width: 49.9vw;
  }
}

@media screen and (min-width: 1440px) {
  .col6vw {
    max-width: 44.3vw;
    min-width: 44.3vw;
    width: 44.3vw;
  }
}

@media screen and (min-width: 1600px) {
  .col6vw {
    max-width: 39.9vw;
    min-width: 39.9vw;
    width: 39.9vw;
  }
}

@media screen and (min-width: 1920px) {
  .col6vw {
    max-width: 33.2vw;
    min-width: 33.2vw;
    width: 33.2vw;
  }
}

.col3 {
  max-width: 24.9%;
  width: 24.9%;
}

.col3vw {
  max-width: 320px;
  min-width: 320px;
  width: 320px;
}

@media screen and (min-width: 1280px) {
  .col3vw {
    max-width: 24.9vw;
    min-width: 24.9vw;
    width: 24.9vw;
  }
}

@media screen and (min-width: 1440px) {
  .col3vw {
    max-width: 22.1vw;
    min-width: 22.1vw;
    width: 22.1vw;
  }
}

@media screen and (min-width: 1600px) {
  .col3vw {
    max-width: 19.9vw;
    min-width: 19.9vw;
    width: 19.9vw;
  }
}

@media screen and (min-width: 1920px) {
  .col3vw {
    max-width: 16.5vw;
    min-width: 16.5vw;
    width: 16.5vw;
  }
}

.col2 {
  max-width: 16.5%;
  width: 16.5%;
}

.col2vw {
  max-width: 213px;
  min-width: 213px;
  width: 213px;
}

@media screen and (min-width: 1280px) {
  .col2vw {
    max-width: 16.5vw;
    min-width: 16.5vw;
    width: 16.5vw;
  }
}

@media screen and (min-width: 1440px) {
  .col2vw {
    max-width: 14.8vw;
    min-width: 14.8vw;
    width: 14.8vw;
  }
}

@media screen and (min-width: 1600px) {
  .col2vw {
    max-width: 13.3vw;
    min-width: 13.3vw;
    width: 13.3vw;
  }
}

@media screen and (min-width: 1920px) {
  .col2vw {
    max-width: 11vw;
    min-width: 11vw;
    width: 11vw;
  }
}
