@import (reference) "../../styles/less/colors.less";

.tile {
  background-color: @color-widget-background;
  border: solid 1px @color-widget-border;
  margin: 5px;

  > .tile-content {
    padding: 15px;

    .title {
      font-weight: normal;
      word-wrap: break-word;
    }
  }

  > .tile-content.processing,
  > .tile-content.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
  }

  > .tile-footer {
    border-top: solid 1px @color-widget-border;
    padding: 5px 15px;

    > .explore {
      display: flex;
      align-items: center;

      > span {
        margin: 5px 0px;
      }
    }
  }
}
