@import (reference) "../../../../../styles/less/colors.less";
@import (reference) "../../../../../styles/less/variables.less";

@pilot-cell-color: #7c71a7;
@reference-color: #7c71a7;

.battery-conductance .battery-conductance-chart-info {
  display: flex;
  justify-content: space-around;
  font-size: @font-size-small;

  .values-info,
  .thresholds-info {
    display: flex;

    > div {
      display: flex;
      align-items: center;

      span + span {
        margin-left: 8px;
      }
    }

    > div + div {
      margin-left: 24px;
    }

    [class$="-indicator"] {
      display: inline-block;
      height: 1.5ch;
      width: 1.5ch;
    }

    .pilot-cell-indicator {
      border: solid 2px @pilot-cell-color;
    }

    .warning-indicator {
      background: @color-status-yellow;
    }

    .alert-indicator {
      background: @color-status-orange;
    }

    .drop-magnitude-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      height: initial;
      width: initial;

      > div + div {
        margin-left: 4px;
      }
    }

    .reference-level-indicator,
    .warning-level-indicator,
    .alert-level-indicator {
      height: 0ch;
      width: 2ch;
      border-style: dashed;
      border-width: 1px;
    }

    .reference-level-indicator {
      border-color: @reference-color;
    }

    .warning-level-indicator {
      border-color: @color-status-yellow;
    }

    .alert-level-indicator {
      border-color: @color-status-orange;
    }
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 18px solid @color-blue-50;

    &.medium {
      border-left-width: 10.5px;
      border-right-width: 10.5px;
      border-top-width: 14px;
    }

    &.small {
      border-left-width: 8px;
      border-right-width: 8px;
      border-top-width: 10px;
    }
  }
}
