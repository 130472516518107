// Copyright 2016-2023 Hitachi Energy. All rights reserved.
@import (reference) "styles/less/colors.less";

@family-icon-space: 1.7em;

.asset-detail-risk {
  .asset-ribbon-column {
    align-items: center;

    &.with-family {
      margin-right: @family-icon-space;
    }
  }

  .asset-detail-risk-icon {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    box-shadow: 1px 1px 1px rgba(100, 100, 100, 0.17);

    &.asset-risk-bar-value.unknown {
      background-color: @color-text-secondary-on-light;
    }

    &.asset-risk-bar-value.not-determined {
      background-color: transparent;
    }
  }
  .risk-column-family {
    position: relative;

    .asset-risk-family {
      position: absolute;
      top: 0;
      right: -@family-icon-space;
    }
  }
  .family-info-popover-off {
    opacity: 0.1;
    pointer-events: none;
  }
}
