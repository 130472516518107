@import (reference) "../../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../../styles/less/variables.less";

.parameter-histogram {
  .parameter-histogram-chart-title {
    font-size: @font-size-base;
    color: @color-gray-90;
    font-weight: 600;
    transform: translate(40px, 25px);
  }
}
