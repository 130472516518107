@import (reference) "../../../components/common/widget/Widget.less";
@import (reference) "../../../styles/less/legacy.less";

.detail-page-new {
  .charts-wrapper-mixin();

  &.left-panel-expanded {
    max-width: calc(~"100vw - 320px");
  }

  .family-info-popover-icon {
    color: @color-gray-90;
    opacity: 0.35;

    &.ant-popover-open {
      color: @color-primary;
      opacity: 1;
    }

    &:hover {
      color: @color-gray-90;
      opacity: 1;
      cursor: pointer;
    }
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;

  .panels-container {
    display: flex;

    .panel-left {
      display: flex;
      justify-content: center;
    }

    .panel-left,
    .panel-right {
      height: calc(~"100vh - 135px");
      max-height: calc(~"100vh - 135px");
    }

    .panel-right {
      border-left: solid 1px #efefef;
      background-color: white;
      position: relative;

      .right-title-panel {
        background-color: @color-gray-20;
        height: 64px;
        display: flex;
        align-items: center;
      }

      .right-title {
        display: inline-block;
        position: relative;
        margin: 0;
      }

      .icon.expand {
        cursor: pointer;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        color: @color-gray-90;
        position: relative;

        &.left {
          -ms-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
          -ms-transition: transform 0.2s ease-in;
          -moz-transition: transform 0.2s ease-in;
          -webkit-transition: transform 0.2s ease-in;
          transition: transform 0.2s ease-in;
        }

        &.right {
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          -ms-transition: transform 0.2s ease-in;
          -moz-transition: transform 0.2s ease-in;
          -webkit-transition: transform 0.2s ease-in;
          transition: transform 0.2s ease-in;
        }
      }
    }

    .panel-0 {
      display: none;
    }

    .panel-4 {
      width: 100%;
      min-width: 99%;
      max-width: 100%;
    }

    .panel-1 {
      width: 25%;
      min-width: 24%;
      max-width: 25%;
    }

    .panel-2 {
      width: 50%;
      min-width: 49%;
      max-width: 50%;
    }

    .panel-3 {
      width: 75%;
      min-width: 74%;
      max-width: 75%;
    }

    .widgets {
      display: flex;
      flex-direction: column;
      padding: @widget-content-margin;
      overflow-x: auto;
    }

    .absolute-min-col12vw {
      margin: auto;
      min-width: 1280px;
      width: 100%;
    }

    .max-col12vw {
      margin: auto;
      max-width: 1280px;
    }

    @media screen and (min-width: 1280px) {
      .max-col12vw {
        max-width: 99.9vw;
      }
    }

    @media screen and (min-width: 1440px) {
      .max-col12vw {
        max-width: 88.6vw;
      }
    }

    @media screen and (min-width: 1600px) {
      .max-col12vw {
        max-width: 79.9vw;
      }
    }

    // NOTE: Min width is 1921px, not 1920px
    @media screen and (min-width: 1921px) {
      .max-col12vw {
        max-width: 66.5vw;
      }
    }
  }

  .charts-wrapper-mixin {
    .charts-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 167px);

      .widget {
        height: 100%;

        .widget-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          min-height: 100%;

          .widget-body {
            display: flex;
            width: 100%;
            height: 100%;

            .bb {
              height: 100%;
              min-height: 100%;

              svg {
                height: 100%;
              }
            }

            .spinner {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .error-message {
              & > div {
                align-items: center;
              }
            }

            .content {
              height: 100%;

              & > div {
                height: 100%;
              }
            }
          }

          .widget-body > div:not(.spinner) {
            width: 100%;
          }
        }
      }

      .widget.risk-matrix-widget {
        position: relative;

        .widget-body {
          .risk-matrix-container {
            height: 100%;
            width: 100%;
          }
        }

        .risk-matrix-asset-details:not(.expanded) {
          position: absolute;
          width: 95%;
          bottom: 0px;
          margin: 0px;
        }

        // Targeting for browser IE8 and newer
        @media screen\0 {
          .overlay-component {
            .risk-matrix-asset-details {
              opacity: 0.2;
            }
          }
        }
      }
    }
  }
}
