@import (reference) "../../../../../styles/less/colors.less";

.maintenance-score-details {
  padding: 12px;

  .mp-equation {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
  }

  .components {
    width: 100%;

    tr {
      line-height: 1.7;

      td:not(:first-child) {
        text-align: right;
      }
    }

    .divider-cell {
      height: 20px;

      .divider {
        width: 100%;
        border-top: 1px solid @color-gray-20;
      }
    }
  }
}
