@import (reference) "../../../../../../styles/less/colors.less";

.grid-replacement-priority {
  .common.data-grid {
    .grid-cell {
      padding: 0.5em;

      &.value {
        text-align: right;
        color: @color-primary-dark;
        font-weight: 600;
      }

      &.percentage-of-max-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .percentage-wrapper {
          min-width: 55px;
          text-align: left;
        }

        .progress {
          width: 60%;

          .progress-bar {
            background-color: @color-gray-60;
          }
        }
      }
    }

    .grid-body {
      .grid-row:not(:hover) {
        & > .grid-cell.frozen {
          &:nth-child(2),
          &:nth-child(3) {
            background-color: white;
          }
        }
      }
    }
  }
}
