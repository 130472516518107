.prognostic-widget {
  position: relative;
  display: flex;
  flex-direction: column;

  &__chart,
  &__grid {
    flex: 0 1 100%;
    overflow-y: hidden;
  }

  .prognostic-switch {
    margin-left: 10px;
    & > div {
      display: flex;
    }
  }
}
