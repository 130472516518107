@import (reference) "styles/less/colors.less";

.detail-page-widgets {
  .layout-content {
    background-color: white;
  }

  .no-visualization-message,
  .select-report-message {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon.no-visualizations,
    .icon.select-report {
      color: @color-status-blue;
    }
  }
}
