.xlsx-downloader {
  .items {
    padding: 10px 20px;
    .item {
      display: flex;
      flex-direction: row;
      padding: 15px 0;

      > span {
        padding-right: 10px;
      }
      + div {
        border-top: 1px solid #efefef;
      }
    }
  }
  .buttons {
    padding: 10px;
    padding-top: 10px;
    input[type="submit"]:extend(.primary-button-on-light all) {
      width: 60%;
    }
    input[type="button"]:extend(.normal-button-on-light all) {
      width: 40%;
    }

    > button + button {
      margin-left: 10px;
    }
  }

  .buttons button {
    min-width: 48%;
  }
}

.modal-window .header + div.xlsx-downloader {
  padding: 0;
}
