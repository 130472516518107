.common-form {
  > button {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 12px;
  }

  > button + button {
    margin-left: 8px;
  }
}
