@import (reference) "../../../styles/less/legacy.less";

.asset-nameplate-attributes-container {
  .attribute {
    display: inline-block;
    vertical-align: top;
    width: 33%;

    &.large-content {
      width: 99%;
    }

    .labeled-row {
      .label:extend(.default-grey-label) {
      }

      .value:extend(.paragraph-on-light) {
      }
    }
  }
}
