.asset-risk-dashboard {
  &__row {
    margin: 0;
    &--center {
      justify-content: center;
    }
  }
  &__header {
    margin: 12px auto 0 auto;
    display: flex;
    align-items: center;
    .section-name {
      margin-right: 21px;
    }
  }
}
