@label-margin: 10;

[class*="-control"] {
  [class*="-messages"] {
    font-size: @font-size-base;

    > div {
      display: flex;

      .icon {
        color: @color-status-red;
        font-size: 20px;
      }

      .text {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
    }

    > div + div {
      margin-top: 4px;
    }
  }
}
