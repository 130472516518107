.phases-info {
  > div + div {
    margin-top: 6px;
  }

  .indicator {
    border-radius: 50%;
    background: black;
    display: inline-block;
    height: 10px;
    margin-right: 6px;
    width: 10px;
  }
}
