@import (reference) "../../styles/less/colors.less";

.common-error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;

  .icon {
    color: @color-status-red;
  }

  > span + span {
    margin-left: 12px;
  }
}
