@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.aging-operations {
  &__row {
    justify-content: flex-end;
  }

  &.chart-legend {
    margin: -10px 0 10px 0px;
    display: block;
    &__item {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
    }

    &__item-box {
      position: relative;
      width: 25px;
      height: 13px;
      border-radius: 4px;
      margin-right: 0.5em;
      opacity: 0.6;
      display: flex;
      align-items: center;

      .aging-legend-grid-line {
        width: 100%;
        border-top: 2px dotted black;
        opacity: 0.6;
      }
    }
    &__item-text {
      font-weight: 600;
      font-size: @font-size-small;
      font-weight: 500;
      color: @color-text-secondary-on-light;
    }
  }
}
