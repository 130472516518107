.labeled-row {
  padding: 2px;
}

.labeled-row .label {
  color: #a0a0a0;
  font-size: 10pt;
  text-transform: uppercase;
}

.labeled-row .value {
  font-size: 10.5pt;
  margin-left: 12px;
}
