@import (reference) "../../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../../styles/less/variables.less";

.parameters-tab .parameters-list {
  .parameters-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;

    .search {
      width: 200px;
    }

    .clear {
      margin-left: 12px;
    }

    .export-button {
      display: flex;
      align-items: center;

      .icon {
        margin: 6px;
      }
    }
  }

  .parameters-grid {
    height: 360px;

    > div {
      height: 100%;
    }

    .column {
      padding: 8px;

      &.value {
        height: 100%;
        position: relative;

        .background {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          opacity: 0.1;
        }
      }

      &.value .icon.Down,
      &.value .icon.Up {
        color: @color-primary;
        display: inline-block;
      }

      &.value .icon.Down {
        transform: rotate(180deg);
      }

      &.value .icon.None {
        display: none;
      }

      &.name,
      &.value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .popover-container {
      .action-histogram-popover-icon,
      .action-family-info-popover-icon {
        color: @color-gray-90;
        opacity: 0.35;

        &.ant-popover-open {
          color: @color-primary;
          opacity: 1;
        }

        &:hover {
          color: @color-gray-90;
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  .parameters-grid-footer {
    padding: 8px;

    .count {
      margin-left: 4px;
    }
  }
}

.column-factor-tooltip {
  > .factor + .factor {
    margin-top: 8px;
  }

  .subfactors .factor .factor-name {
    font-size: @font-size-small;
    margin-left: 8px;
  }
}
