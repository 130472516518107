@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";
@import "./Control.less";

.toggle-control {
  align-items: center;
  cursor: pointer;
  display: flex;

  .switch {
    border-radius: 12px;
    height: 24px;
    min-height: 24px;
    min-width: 48px;
    padding: 2px;
    width: 48px;

    > .icon {
      align-items: center;
      background-color: white;
      border-radius: 50%;
      display: flex;
      font-weight: 600;
      height: 20px;
      justify-content: center;
      width: 20px;
    }
  }

  .control-label {
    display: block;
    margin-left: 10px;
  }

  &.on {
    .switch {
      background-color: @color-primary;

      > .icon {
        color: @color-primary;
        margin-left: 24px;
      }
    }
  }

  &.off {
    .switch {
      background-color: @color-gray-30;

      > .icon {
        color: black;
        margin-left: 0px;
      }
    }
  }

  &:not(.disabled) {
    &.on {
      .switch:hover {
        background-color: @color-primary-dark;

        > .icon {
          color: @color-primary-dark;
        }
      }
    }

    &.off {
      .switch:hover {
        background-color: @color-gray-40;
      }
    }
  }

  &.disabled {
    cursor: inherit;
    opacity: 0.65;
  }

  &.animate {
    &.on .switch .icon {
      animation: input-toggle-on 0.3s;
    }

    &.off .switch .icon {
      animation: input-toggle-off 0.3s;
    }
  }

  &.label-on-top {
    flex-direction: column;
    align-items: flex-start;

    label {
      margin-bottom: @font-size-x-small;
    }
  }

  &.label-on-left {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &.label-on-right {
    flex-direction: row-reverse;
  }

  &.label-on-left,
  &.label-on-right {
    label {
      margin-left: @font-size-x-small;
      margin-right: @font-size-x-small;
    }
  }
}

@-webkit-keyframes input-toggle-on {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 24px;
  }
}

@-moz-keyframes input-toggle-on {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 24px;
  }
}

@keyframes input-toggle-on {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 24px;
  }
}

@-webkit-keyframes input-toggle-off {
  0% {
    margin-left: 24px;
  }
  100% {
    margin-left: 0px;
  }
}

@-moz-keyframes input-toggle-off {
  0% {
    margin-left: 24px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes input-toggle-off {
  0% {
    margin-left: 24px;
  }
  100% {
    margin-left: 0px;
  }
}
