@import (reference) "../../styles/less/variables.less";

h3.section-name {
  margin-bottom: 0;
  margin: @spacing-x-large 0;
  padding: @spacing-medium 0;

  span {
    white-space: nowrap;
  }
}
