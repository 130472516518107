.chart-legend {
  margin-top: -20px;
  position: relative;
  font-size: 10px;
  padding: 0 10px;

  .noactive {
    opacity: 0.4;
  }

  .active {
    opacity: 1;
  }

  .disabled {
    opacity: 0.2;
  }
}
