@import (reference) "../../styles/less/colors.less";

.message {
  background-color: white;
  box-shadow: 1px 1px 1px #a2a2a2;
  border: 1px solid #a2a2a2;
  padding: 12px;
  font-size: 11pt;
  white-space: pre-wrap;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.message-info {
  background-color: @color-status-blue;
  border: none;
  color: white;
}

.message-warning {
  background-color: @color-status-orange;
  border: none;
  color: white;
}

.message-error {
  background-color: @color-status-red;
  border: none;
  color: white;
}

.message-confirmation {
  background-color: @color-status-green;
  border: none;
  color: white;
}
