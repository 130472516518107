@import (reference) "../../../styles/less/variables.less";
@import (reference) "../../../styles/less/colors.less";

.asset-query {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }

  .grid-container {
    height: 100%;
    overflow: hidden;

    > div {
      height: 100%;
      padding-bottom: @spacing-x-large;
    }
    
    .grid-row { 
      &.removed {
        &:not(:hover):not(.checked) {
          .grid-cell, .grid-cell.frozen {
            background-color: fade(@color-gray-40, 8%);
          }
        }

        .grid-cell:first-of-type {
          border-left: @spacing-small solid @color-status-gray;
        }
      }

      &.in-reserve {
        .grid-cell:first-of-type {
          border-left: @spacing-small solid @color-status-purple;
        }
      }
    }

    .header-row {
      display: flex;
    }

    .header {
      display: flex;
      align-items: center;

      .status-panel {
        margin-left: @spacing-xx-large;
      }
    }

    .asset-buttons {
      margin: auto 0 auto auto;
      .add-icon {
        margin-right: @spacing-medium;
      }

      .expand-more-icon {
        margin-left: @spacing-medium;
      }
    }
  }
}
