@import (reference) "./colors.less";
@import (reference) "./variables.less";

@font-family: "Open Sans", Helvetica, Arial, sans-serif;
@primary-color: @color-primary;

@error-color: @color-status-red;
@warning-color: @color-status-orange;
@info-color: @color-status-blue;
@success-color: @color-status-green;

@screen-xs: @screen-size-xs;
@screen-sm: @screen-size-sm;
@screen-md: @screen-size-md;
@screen-lg: @screen-size-lg;
@screen-xl: @screen-size-xl;
@screen-xxl: @screen-size-xxl;

@text-color: @color-text-primary-on-light;
@text-color-secondary: @color-text-secondary-on-light;

@text-color-dark: @color-text-primary-on-dark;
@text-color-secondary-dark: @color-text-secondary-on-dark;

@menu-inline-submenu-bg: @color-gray-0;
@menu-highlight-color: @color-gray-100;
@menu-item-active-bg: @color-widget-background;

@drawer-bg: @color-widget-background;

.ant-drawer-content-wrapper {
  padding-top: 1px;
}

.ant-slider {
  .ant-slider-rail {
    width: calc(100% + 6px);
    margin-left: -3px;
  }
}

/* --- z-index override --- */
.modal-window-overlay,
.ant-image-preview-mask,
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 2000 !important;
}

.ant-message,
.ant-notification {
  z-index: 2010 !important;
}

.ant-popover {
  z-index: 2030 !important;
}

.ant-picker-dropdown,
.ant-dropdown,
.ant-dropdown-menu-submenu-popup,
.ant-mentions-dropdown,
.ant-menu-submenu-popup,
.ant-select-dropdown,
.ant-tabs-dropdown {
  z-index: 2050 !important;
}

.ant-popconfirm {
  z-index: 2060 !important;
}

.ant-tooltip {
  z-index: 2070 !important;
}

.ant-image-preview-wrap {
  z-index: 2080 !important;
}

/* --- fonts and typography override --- */
.ant-typography {
  font-family: @font-family;
  overflow-wrap: break-word;
  word-break: normal;
  font-size: unset;
  line-height: unset;
  margin-top: 0;
}

.ant-col {
  font-size: inherit;
}

.ant-card {
  font-family: @font-family;
}

/* --- buttons override --- */
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.ant-btn-primary {
    box-shadow: 0 2px 0 rgba(0,0,0,.045);

    &[disabled]:hover,
    &[disabled] {
      box-shadow: none;
      background-color: rgba(56, 129, 141, 0.15);
      border-color: rgba(86, 146, 153, 0.15);
      color: rgba(119, 163, 166, 0.5);
    }
  }

  &:focus {
    outline: none;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-btn:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child) {
  margin-inline-end: 0;
}

.ant-switch {
  &:focus {
    outline: none;
  }
}

/* --- dropdowns override --- */
.ant-select-dropdown,
.ant-cascader-dropdown .ant-cascader-menu,
.ant-dropdown-menu.ant-dropdown-menu-vertical {
  padding-left: 0;
  padding-right: 0;
}

.ant-select-dropdown .ant-select-item,
.ant-cascader-menu .ant-cascader-menu-item,
.ant-dropdown-menu.ant-dropdown-menu-vertical .ant-dropdown-menu-item {
  border-radius: 0;
}

/* --- modals override --- */
.ant-popover-inner-content {
  padding: 0 4px;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid @color-app-background;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-footer {
  padding: 10px 16px;
  border-top: 1px solid @color-app-background;
}

.ant-modal .ant-modal-close:hover {
  background: transparent;
}

.ant-modal.echarts-modal .ant-modal-close,
.ant-modal.bb-chart-modal .ant-modal-close {
  top: 10px;
}

/* --- ConfigurationTool form inputs override --- */
.ant-form-item .ant-form-item-explain-error {
  color: @error-color;
}

.ant-form-item .ant-input.ant-input-status-error,
.ant-form-item .ant-input-number.ant-input-number-status-error,
.ant-form-item .ant-picker.ant-picker-status-error {
  border-color:  @error-color !important;
}

.ant-form-item .ant-select.ant-select-status-error .ant-select-selector {
  border-color:  @error-color !important;
}

/* --- Upload links override --- */
.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-done .ant-upload-list-item-name {
  font-family: @font-family;
  color: @color-teal-40;
}

.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-done .ant-upload-icon .anticon {
  color: @color-teal-40;
}

.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-error .ant-upload-list-item-name {
  font-family: @font-family;
  color: @color-status-orange;
}

.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-error .ant-upload-icon .anticon {
  color: @color-status-orange;
}

/* --- other styles override --- */
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(56, 129, 141, 0.08);
}

.ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: @color-teal-90;
}

.ag-cell .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
