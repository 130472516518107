.licenses {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .abb-disclaimer {
    margin: 32px 0 24px 0;
  }

  .data-grid__parent,
  .data-grid__scroll {
    height: 100%;
  }
}
