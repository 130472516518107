@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";
@import "./Control.less";

@select-height: 45px;

.select-control {
  .select-body-wrapper {
    position: relative;
  }
  .control-label {
    margin-bottom: unit(@label-margin, px);

    & > span + span {
      margin-left: 1ch;
    }
  }

  .select-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;

    .selected-option {
      width: 100%;
    }

    .expand-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1ch;
      color: @color-text-secondary-on-light;
      font-size: 8pt;
    }
  }

  .select-options-wrapper {
    z-index: 1;
    position: absolute;

    .select-options {
      background-color: white;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      left: 0px;
      max-height: calc(5 * @select-height);
      min-width: 160px;
      overflow-y: auto;
      width: 100%;
      z-index: 1;

      .select-option {
        cursor: pointer;
        font-weight: 600;
        min-height: 33px;

        & > .option {
          padding: 8px 2ch;
          &.active {
            background-color: @color-primary;
            color: white;
          }
        }

        &:hover {
          background-color: @color-gray-10;
        }
      }
    }
  }

  .select-messages {
    margin-top: 6px;
  }

  &.disabled {
    .select-container {
      cursor: unset;

      .selected-option .select-option {
        color: @color-text-secondary-on-light;
      }

      .expand-arrow {
        display: none;
      }
    }
  }

  &.primary {
    .select-container {
      background-color: white;
      height: @select-height;
      max-height: @select-height;
      min-height: @select-height;
      border: solid 1px @color-gray-40;
    }

    &.disabled {
      .select-container {
        background-color: #ebebe4;
      }
    }
  }

  &.light {
    .select-container {
      padding-left: 0;
      padding-right: 0;

      .selected-option,
      .expand-arrow {
        font-weight: 600;
      }

      .selected-option {
        color: @color-primary;
        text-transform: uppercase;
      }
    }
  }

  &.error {
    .select-container {
      border-bottom: solid 3px @color-status-red;
    }
  }
}
