// Copyright 2016-2023 Hitachi Energy. All rights reserved.
@import (reference) "../../../../../styles/less/colors.less";

.asset-ribbon-toggle {
  position: relative;
  height: 100%;

  &.active,
  &:hover {
    box-shadow: inset 0px 10px 0px -5px @color-primary;
  }

  &.active {
    background-color: @color-widget-background;
  }
}
