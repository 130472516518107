@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";

@widget-content-margin: 5px;

.widget {
  display: inline-block;
  overflow: hidden;

  .widget-content {
    background-color: #ffffff;
    border: solid 1px #efefef;
    margin: @widget-content-margin;
    min-height: 75px;
    padding: 15px;

    .widget-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      .expand {
        cursor: pointer;
      }
    }

    .widget-body {
      .spinner.margin {
        margin: 40px auto;
      }
    }
  }

  &-message:extend(.paragraph-on-light all) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;

    > span + span {
      margin-left: 10px;
      font-size: @font-size-base;
      color: @color-gray-100;
    }

    .icon {
      font-size: 18px;

      &.no-data {
        color: @color-status-blue;
      }

      &.error {
        color: @color-status-red;
      }

      &.good-job {
        color: @color-status-green;
      }
    }
  }
}
