@import (reference) "../../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../../styles/less/variables.less";

.rogers-ratio {
  &__wrapper {
    padding: @spacing-xx-large;

    .data-source-form {
      display: flex;
      justify-content: flex-end;
      margin: 0px 0px 15px 0;
      .label {
        align-self: center;
        padding-right: 10px;
        font-weight: 600;
      }
    }
  }
}

.rogers-container {
  width: 100%;
  min-height: calc(100vh - 305px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: @spacing-xxxx-large;

    .grids,
    .scales,
    .cubes,
    .traces {
      transform: translateY(-75px);
    }

    .scales {
      .xText {
        transform: translate(-2px, 7px);
      }
      .zText {
        transform: translate(12px, 7px);
      }
      .yText {
        transform: translate(5px, 5px);
      }
    }
  }

  &__resize-wrapper {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .rogers-ratio-tooltip {
    position: fixed;
    width: auto;
    height: auto;
    background-color: white;
    pointer-events: none;
    font-size: @font-size-base;
    border: 1px solid @color-gray-40;
    padding: 0;
    opacity: 0.95;

    table {
      border: 0;

      tr {
        &:not(:first-child) {
          td {
            border-top: 1px solid @color-gray-40;
          }
        }
      }

      td {
        margin: 0;
        border: 0;
        padding: 0 10px 0 10px;
        white-space: nowrap;

        &:not(:last-child) {
          border-right: 1px dashed @color-gray-40;
        }
      }
    }

    &__header {
      box-sizing: border-box;
      padding: 2px 10px 2px 10px;
      width: 100%;
      text-align: left;
      font-size: @font-size-base;
      color: white;
      background-color: @color-gray-40;
      font-weight: 600;
    }

    &__left {
      text-align: left;
    }

    &__right {
      text-align: right;
      padding-right: 5px;
    }
  }
}
