@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.aging-visualization {
  .aging-visualization-chart {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .failed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    font-size: @font-size-base;

    .icon {
      color: @color-status-red;
      margin-right: 10px;
    }
  }
}
