@import (reference) "../../../styles/less/colors.less";

.rank-tooltip {
  color: @color-text-primary-on-light;
  max-width: 300px;
  font-weight: normal;
  .text {
    padding: 0 10px;
    .rank {
      font-weight: 600;
    }
  }
  .warning {
    color: @color-status-orange;
    font-weight: 600;
  }
  hr {
    margin: 5px 0;
    background-color: white;
  }
}
