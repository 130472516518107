@import (reference) "../../../../../styles/less/variables.less";

.details-page-issues .issue {
  .actions {
    .action-list {
      margin: 12px 24px;

      .action-list-header .action-list-row,
      .action-list-body .action-list-row {
        display: flex;
        padding: 8px 0;

        .id,
        .description,
        .scheduled,
        .status {
          flex-basis: 0;
          padding: 0 4px;
          word-break: break-all;
        }

        .scheduled,
        .status {
          flex-grow: 4;
        }

        .id {
          flex-grow: 5;
        }

        .description {
          flex-grow: 10;
        }
      }

      .action-list-header .action-list-row {
        .id,
        .description,
        .scheduled,
        .status {
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .action-list-header .action-list-row {
        border-bottom: solid 1px #c9c9c9;
      }

      .action-list-body {
        .no-actions-assigned {
          margin: 24px 0;
        }
      }

      .action-list-body .action-list-row + .action-list-row {
        border-top: solid 1px rgba(210, 210, 210, 0.8);
      }

      .action-list-body .action-list-row.action {
        .id {
          font-weight: 600;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;

      .ant-btn.add-action {
        align-items: center;
        display: flex;
        text-decoration: none;

        .icon {
          font-size: 18px;
        }

        > span + span {
          margin-left: 4px;
        }
      }
    }
  }

  .action-description-tooltip {
    font-size: @font-size-small;
    font-weight: 600;
    max-width: 600px;
  }
}
