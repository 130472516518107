@import (reference) "../../../../../styles/less/colors.less";

.bb.asset-risk-chart {
  table.bb-tooltip.asset {
    tr.bb-tooltip-name-importance,
    tr.bb-tooltip-name-score {
      td > span {
        background-color: white;
      }
    }

    tr.bb-tooltip-name-risk {
      &.bb-tooltip-name-risk-high td > span {
        background-color: @color-risk-high;
      }

      &.bb-tooltip-name-risk-medium td > span {
        background-color: @color-risk-medium;
      }

      &.bb-tooltip-name-risk-low td > span {
        background-color: @color-risk-low;
      }

      &.bb-tooltip-name-risk-unknown td > span {
        background-color: @color-risk-unknown;
      }
    }
  }

  &.asset-risk-chart-grayscale {
    table.bb-tooltip {
      tr.bb-tooltip-name-risk {
        &.bb-tooltip-name-risk-high td > span {
          background-color: #919191 !important;
        }

        &.bb-tooltip-name-risk-medium td > span {
          background-color: #b7b7b7 !important;
        }

        &.bb-tooltip-name-risk-low td > span {
          background-color: #b5b5b5 !important;
        }
      }
    }
  }
}
