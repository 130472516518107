.detail-page-new {
  .replacement-score-widget-wrapper {
    .widget {
      width: 100%;

      .widget-content {
        border: none;

        .spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 4em;
        }
        .widget-message {
          justify-content: center;
        }
      }
    }
  }
}
