@import (reference) "../../../../../styles/less/colors.less";

.detail-page-new .messages {
  &.no-data {
    margin-top: -25px;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;
    margin-top: -35px;

    > div + div {
      margin-left: 50px;
    }
  }

  .column-type {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 100%;

    .icon path {
      fill: @color-primary-dark;
    }

    .icon.message-alert path {
      fill: @color-status-orange;
    }

    .icon.warning path {
      fill: @color-status-yellow;
    }

    .icon.informational path {
      fill: @color-status-blue;
    }

    .icon.error path {
      fill: @color-status-red;
    }
  }

  .column-message {
    padding: 10px;
    white-space: break-spaces;
  }

  .column-date {
    padding: 10px;
  }

  .footer {
    padding: 10px;
  }
}
