@import (reference) "../../../../../styles/less/colors.less";

.reports-drop-down {
  @list-shadow: 1px 1px 12px rgba(100, 100, 100, 0.17);
  @drop-down-height: 40px;
  &.reports {
    position: relative;
    text-align: left;
    .drop-down {
      cursor: pointer;
      .reports-title {
        display: flex;
        align-items: center;
        .icon {
          &.top {
            transform: rotate(-180deg);
          }
        }
      }
    }
    .list-container {
      position: absolute;
      z-index: 100;
      width: 200px;
      left: 0;
      right: 0;
      box-shadow: @list-shadow;
      background-color: white;
      .report-list {
        > .scrollarea {
          max-height: 400px;
          .linked-reports {
            border-top: solid 1px @color-gray-10;
          }
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            div:extend(.single-line-content-on-light, .dropdown-text),
            span:extend(.single-line-content-on-light, .dropdown-text) {
              display: block;
              padding: 7px 15px;
              text-decoration: none;
              font-weight: 600;
            }
            div {
              color: @color-primary;
            }
          }
          li:hover {
            background-color: @color-gray-10;
          }
        }
        .empty-reports:extend(.single-line-content-on-light) {
          padding: 0.5em;
        }
      }
    }
  }
  &.processing {
    display: flex;
    align-items: center;
    justify-content: center;
    height: @drop-down-height;
  }
  &.error-message {
    .error-icon {
      transform: rotate(180deg);
      color: @color-status-red;
      font-size: 30px;
      display: inline-block;
    }
  }
  .spinner {
    width: auto;
    padding: 0.5em;
  }
}

.dropdown-text {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}
