.model-configuration-grid-container {
  padding: 0 !important;
  overflow-y: auto;

  .model-configuration-grid-scroll {
    
    .ag-body-viewport.ag-layout-normal {
      max-height: calc(~"80vh - 80px");
    }

    .common.data-grid {
      min-width: 1788px;
      .grid-cell {
        padding: 10px 15px;

        .grid-cell-content {
          padding: 0;

          &.numericValue {
            text-align: right;
          }

          &.textValue {
            text-align: left;
          }
        }
      }
    }
  }
}
