@import (reference) "../../../../../styles/less/legacy.less";

.homepage .key-risk-changes {
  .items-group:extend(.default-grey-label) {
    justify-content: space-between;
    padding-top: 14px;
    margin-left: 6px;

    > .key-risk-item ~ div {
      border-top: solid 1px @color-gray-10;
    }

    > .key-risk-item {
      max-width: 260px;
      padding: 6px 0;
      margin-left: 6px;
      > div {
        min-height: 12px;
        align-items: center;
        padding-left: 5px;
        .asset-id {
          flex-grow: 1;
          max-width: 240px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      > border-left {
        border-left: 1px solid;
      }
      .asset-details {
        color: @color-text-primary-on-light;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
