@import (reference) "../../../../../styles/less/colors.less";
@import (reference) "../../../../../styles/less/variables.less";

.slide-panel-content {
  padding: 0 15px;

  .slide-panel-labeled-row {
    margin: 10px 0;
    font-size: @font-size-small;

    span:first-of-type {
      margin-right: 5px;
    }

    &.actions-asset-type,
    &.actions-asset-location {
      display: flex;

      > span {
        display: block;
        width: 1px;
      }

      > span:first-of-type {
        flex-grow: 1;
      }

      > span:last-of-type {
        flex-grow: 2;
      }
    }
  }

  .actions-list {
    margin-top: 40px;

    .actions-list-item:first-of-type {
      margin-top: 30px;
    }

    .actions-list-item:not(:first-of-type) {
      border-top: solid @color-gray-10 1px;
      margin-top: 15px;
      padding-top: 10px;
    }
  }

  &.failed {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @font-size-base;
    min-height: 200px;
    padding: 10px;

    .icon {
      color: @color-status-red;
      margin-right: 10px;
    }
  }
}
