.asset-query {
  .column.manufacturer {
    padding: 10px;

    .manufacturer-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .model {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
