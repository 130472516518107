.column.risk {
  position: relative !important;
  height: 100%;
  width: 100%;

  .asset-risk-bg {
    position: absolute;
    left: calc(~"50% - 8px");
    top: calc(~"50% - 8px");
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}
