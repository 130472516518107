@import (reference) "../../../../styles/less/legacy.less";

.notifications .degraded-toggle-view {
  max-width: 350px;
  font-size: 14px;

  .item {
    span.disabled {
      color: @color-text-secondary-on-dark;
    }
  }

  &__toggles {
    padding-top: 30px;
  }
}
