@import (reference) "../../../styles/less/colors.less";

.dashboard {
  display: flex;

  > div.side-panel {
    background-color: @color-gray-80;
    color: white;
    display: flex;
    flex-direction: column;
    height: calc(~"100vh - 51px");
    min-width: 46px;
    position: relative;
    width: 46px;

    &.expanded {
      width: 320px;
      min-width: 320px;
    }

    > .panel-header {
      border-bottom: solid 1px @color-gray-90;
      height: 46px;

      > .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        width: 46px;
      }
    }

    > .scroll-area {
      height: calc(~"100% - 51px");
      overflow-y: auto;
    }
  }

  > div.content {
    width: calc(~"100vw - 46px");
  }
}
