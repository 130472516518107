.bb-custom-legend.bb-legend {
  margin-bottom: 12px;
  position: static;

  > div {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.bb-custom-legend {
  .bb-legendItem {
    cursor: pointer;
    font-size: 14px;
    margin: 0 12px;

    .bb-legendBox {
      display: inline-block;
      width: 12px;
      height: 12px;
    }

    .bb-legendText {
      margin-left: 8px;
    }
  }
}
