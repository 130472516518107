@import (reference) "../../../../../styles/less/variables.less";

.phase-angle-chart {
  .point {
    cursor: pointer;
  }

  .phase-angle-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    border: solid 1px rgba(110, 110, 110, 0.9);
    padding: 8px 16px;
    position: fixed;
    visibility: hidden;
    z-index: 10;

    .tooltip-row {
      .tooltip-label {
        font-size: @font-size-small;
        font-weight: 600;
        margin-right: 8px;
      }

      .tooltip-value {
        font-size: @font-size-base;
      }
    }
  }

  .phase-angle-chart-legend {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin-top: -50px;

    .chart-legend {
      margin: 0;
    }
  }
}
