@import (reference) "../../../../styles/less/legacy.less";

.asset-query {
  .column.asset {
    padding: 10px;
    height: 100%;

    .id:extend(.link-label all) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .details {
      font-size: 9pt;
      margin-top: 5px;
    }
  }

  .removed {
    .column.asset {
      .id {
        color: @color-gray-40;

        &:hover {
          color: @color-gray-30;
        }
      }
    }
  }
}
