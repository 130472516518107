@notifications-navbar-bg-color: #e4e4e4;

.notifications .c-navbar {
  padding-left: 80px;
  display: flex;
  justify-content: space-between;
  background-color: @notifications-navbar-bg-color;

  .header {
    height: 65px;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-top: 12px;
  }

  .header-buttons {
    display: flex;
    align-items: center;

    button {
      min-width: 100px !important;
      margin-right: 10px;
    }

    > div {
      display: flex;
      padding: 0 5px;
    }
  }
}
