@import (reference) "../../styles/less/colors.less";

@default-tooltip-height: 50px;
@tooltip-arrow-size: 10px;
@tooltip-arrow-border: 1px;

.tooltip-container {
  position: fixed;
  z-index: 15000;
  -webkit-box-shadow: 1px 0px 4px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0px 4px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px;

  &.light {
    background-color: @color-widget-background;
  }

  &.dark {
    background-color: @color-gray-100;
    color: white;
  }

  & > .after-arrow,
  & > .before-arrow {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  & > .after-arrow {
    border-color: rgba(136, 183, 213, 0);
    border-width: @tooltip-arrow-size;
    margin-left: -@tooltip-arrow-size;
  }

  &.top-center {
    & > .after-arrow,
    & > .before-arrow {
      top: 100%;
    }
  }

  &.top-right {
    & > .after-arrow,
    & > .before-arrow {
      top: 100%;
    }
  }

  &.top-left {
    & > .after-arrow,
    & > .before-arrow {
      top: 100%;
    }
  }

  &.top-right,
  &.top-center,
  &.top-left {
    &.light {
      & > .after-arrow {
        border-top-color: @color-widget-background;
      }
    }

    &.dark {
      & > .after-arrow {
        border-top-color: @color-gray-100;
      }
    }

    & > .before-arrow {
      border-top-color: @color-widget-background;
    }

    &.light {
      & > .before-arrow {
        border-top-color: @color-widget-background;
      }
    }

    &.dark {
      & > .before-arrow {
        border-top-color: @color-gray-100;
      }
    }
  }

  &.bottom-center {
    & > .after-arrow,
    & > .before-arrow {
      bottom: 100%;
    }
  }

  &.bottom-right {
    & > .after-arrow,
    & > .before-arrow {
      bottom: 100%;
    }
  }

  &.bottom-left {
    & > .after-arrow,
    & > .before-arrow {
      bottom: 100%;
    }
  }

  &.bottom-right,
  &.bottom-center,
  &.bottom-left {
    &.light {
      & > .after-arrow {
        border-bottom-color: @color-widget-background;
      }
    }

    &.dark {
      & > .after-arrow {
        border-bottom-color: @color-gray-100;
      }
    }

    &.before-arrow {
      border-bottom-color: @color-widget-background;
    }

    &.light {
      & > .before-arrow {
        border-bottom-color: @color-widget-background;
      }
    }

    &.dark {
      & > .before-arrow {
        border-bottom-color: @color-gray-100;
      }
    }
  }

  & > .before-arrow {
    border-color: rgba(194, 225, 245, 0);
    border-width: @tooltip-arrow-size + @tooltip-arrow-border;
    margin-left: -(@tooltip-arrow-size + @tooltip-arrow-border);
  }
}
