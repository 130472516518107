@import (reference) "../../../../../../../styles/less/variables.less";

.parameters-tab .parameters-chart-container {
  width: 100%;
  height: 355px;
  margin-bottom: @spacing-xx-large;

  .parameters-chart {
    height: 100%;
  }

  .expand-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-items: center;
  }
}

.parameters-chart-modal-content {
  height: 75vh;
  width: 100%;
}
