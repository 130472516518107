@import (reference) "../../styles/less/colors.less";

.c-hidden {
  height: 0;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}

.c-tooltip {
  position: relative;
  text-decoration: none;
}

@tooltip-font-size: 8.5pt;

.c-tooltip::before {
  background: @color-gray-100;
  bottom: 9999px;
  color: @color-gray-10;
  content: attr(data-tooltip);
  font-size: @tooltip-font-size;
  left: 50%;
  opacity: 0;
  padding: 4px 8px;
  position: absolute;
  -o-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: nowrap;
  z-index: 999;
}

.c-tooltip:hover::before {
  bottom: -38px;
  opacity: 1;
}

.c-tooltip-bottom {
  position: relative;
}

.c-tooltip-bottom::before {
  background: @color-gray-100;
  color: @color-gray-10;
  content: attr(data-tooltip);
  font-size: @tooltip-font-size;
  opacity: 0;
  padding: 4px 8px;
  position: absolute;
  top: -1000px;
  z-index: 999;
}

.c-tooltip-bottom:hover::before {
  opacity: 1;
  top: 100%;
}

.red {
  color: @color-status-red;
}

.green {
  color: @color-status-green;
}

.blue {
  color: @color-status-blue;
}

.yellow {
  color: @color-status-yellow;
}

.border-left {
  border-left: solid 4px !important;
}

.border-red {
  border-color: @color-status-red !important;
}

.border-green {
  border-color: @color-status-green !important;
}

.border-yellow {
  border-color: @color-status-yellow !important;
}

.border-orange {
  border-color: @color-status-orange !important;
}

.border-gray {
  border-color: @color-gray-10 !important;
}

@table-border-color: @color-gray-20;
@table-header-background-color: @color-gray-10;
@table-content-font-size: 10pt;
@table-row-border-color: @color-gray-20;
@table-row-background-hover-color: @color-gray-10;
@table-row-background-selected-color: @color-gray-20;

.grid-custom {
  border: solid 1px @table-border-color;
  position: relative;

  .grid-bottons {
    position: absolute;
    right: 0;
    margin-top: -50px;

    button {
      display: flex;
      align-items: center;

      > span + span {
        margin-left: 5px;
      }

      > span.icon {
        font-size: 15px;
      }
    }
  }

  .grid-header:extend(.default-grey-label all) {
    background-color: @table-header-background-color;
  }

  .grid-body {
    width: 100%;
    background-color: white;
    font-size: @table-content-font-size;

    .grid-row {
      .grid-cell {
        input,
        select {
          border: solid 1px lightgray;
        }

        input {
          padding: 6px;
          width: calc(~"100% - 10px");
        }

        select {
          padding: 5px;
          width: 100%;
        }
      }

      .grid-cell.invalid-value {
        border-bottom: solid 2px @color-status-red;
        padding-bottom: 3px;
      }
    }

    .grid-row:first-of-type,
    .grid-row + .grid-row {
      border-top: solid 1px @table-row-border-color;
    }

    .grid-row:hover {
      background-color: @table-row-background-hover-color;
      cursor: pointer;
    }

    .grid-row.selected {
      background-color: @table-row-background-selected-color;
    }
  }

  .grid-header,
  .grid-body {
    display: flex;
    flex-direction: column;

    .grid-row {
      display: flex;
      justify-content: space-between;
      min-height: 40px;

      .grid-cell {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        word-break: break-all;
      }
    }
  }
}

#label() {
  font-weight: 600;
  font-size: 9pt;
}

.label-on-dark {
  color: @color-text-secondary-on-light;
  #label;
}

.default-grey-label {
  color: @color-text-primary-on-light;
  #label;
}

.link-label {
  color: @color-primary;
  transition: color 0.5s ease;
  #label;
}

.link-label:hover {
  color: @color-primary-light;
  #label;
}

@paragraph-font-weight: 300;
@paragraph-font-size: 10.5pt;

#paragraph() {
  font-weight: @paragraph-font-weight;
  font-size: @paragraph-font-size;
}

.paragraph-on-dark {
  color: @color-text-primary-on-dark;
  #paragraph;
}

.paragraph-on-light {
  color: @color-text-primary-on-light;
  #paragraph;
}

.paragraph-white {
  color: white;
  #paragraph;
}
