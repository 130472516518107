@import "BushingWeeklyChart.less";

.bushing-weekly-info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: space-between;

  > div + div {
    margin-left: 20px;
  }

  .indicator {
    background: black;
    display: inline-block;
    height: 12px;
    margin-right: 6px;
    width: 18px;

    .dashed-line-warning-icon {
      height: 7px;
      border-bottom: dashed 1px @bushing-warning-color;
    }

    .dashed-line-alert-icon {
      height: 7px;
      border-bottom: dashed 1px @bushing-alert-color;
    }
  }
}
