.history-switching-chart {
  height: 100%;
  position: relative;
  .resize-wrapper {
    position: absolute;
    height: 100%;
    .bb {
      height: 100%;
      min-height: 100%;
    }
    svg {
      overflow: visible !important;
      height: 100%;
    }
  }

  &--disabled {
    .bb {
      &-axis,
      &-empty {
        opacity: 0.5 !important;
      }
    }
  }

  .tick line {
    display: none;
  }

  .bb {
    &-ygrid {
      stroke: black !important;
      opacity: 0.1 !important;
      stroke-dasharray: 0;
    }
    &-axis-y {
      path {
        display: none;
      }
    }
    &-line {
      stroke-width: 2px;
    }
  }
}
