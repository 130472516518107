@import (reference) "../../styles/less/legacy.less";

.data-integration {
  font-size: 10.5pt;
  .model-config {
    .asset-risk-config {
      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;

        .asset-risk-chart-wrapper {
          width: 350px;
          height: 300px;
        }

        .chart {
          min-width: 200px;
        }

        .thresholdConfig {
          min-width: 300px;
          max-width: 600px;
          margin-left: 30px;

          .threshold-config-title {
            line-height: 40px;
          }
          .threshold-line-label {
            .default-grey-label;
            padding-top: 15px;
            padding-bottom: 5px;
          }
          .threshold-line-formula {
            line-height: 40px;
            .form-input {
              line-height: normal;
              width: 75px;

              .invalid() {
                box-shadow: none;
                border-bottom: 4px solid @color-status-red !important;
              }
              &:invalid {
                .invalid;
              }

              &:-moz-submit-invalid {
                .invalid;
              }

              &:-moz-ui-invalid {
                .invalid;
              }
            }
          }
          .threshold-line-formula-disabled {
            .threshold-line-formula;
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
  }
}
