@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/legacy.less";

@chart-axis-label-font-size-base: 9.5pt;
@chart-axis-label-font-size-small: 8.5pt;

.scatter-plot-chart {
  background-color: white;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .axis,
  .grid {
    shape-rendering: crispEdges;
    user-select: none;
  }

  .axis {
    path,
    line {
      fill: none;
      stroke: @color-gray-20;
    }

    text {
      font-size: @chart-axis-label-font-size-small;
      font-stretch: 100%;
      fill: @color-gray-60;

      &.axis-label {
        font-size: @chart-axis-label-font-size-base;
      }
    }
  }

  .grid {
    path {
      stroke-width: 0;
    }

    line {
      fill: none;
      stroke: @color-gray-20;
      stroke-dasharray: 3 3;
    }
  }

  .data circle,
  circle.highlight {
    fill: @color-gray-60;
    fill-opacity: 0.7;

    &[data-class="current"] {
      fill: @color-status-blue !important;
    }

    &.asset-risk-dot.high {
      fill: @color-risk-high;
    }

    &.asset-risk-dot.medium {
      fill: @color-risk-medium;
    }

    &.asset-risk-dot.low {
      fill: @color-risk-low;
    }
  }

  .tooltip {
    z-index: 4;
    pointer-events: none;
    position: absolute;
    color: @color-text-secondary-on-light;
    font-size: @tooltip-font-size;

    span:first-child {
      font-weight: 600;
    }

    span.r-squared-value {
      color: @color-status-blue;
      font-weight: 600;
    }
  }

  rect {
    fill: transparent;
    shape-rendering: crispEdges;
    z-index: 1;
  }

  .correlation-line {
    filter: drop-shadow(0 2px 2px @color-primary);
    stroke: @color-status-blue;
    stroke-opacity: 0.7;
    stroke-width: 4;
  }
}
