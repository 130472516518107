@import (reference) "../../../styles/less/colors.less";

.modal-window-overlay {
  background-color: white !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;

  .modal-window-mixed {
    background-color: @color-widget-background;
    border-radius: 0 !important;
    margin: 0 auto;
    max-height: 80vh;
    min-height: 200px;
    position: relative;
    overflow: visible !important;
    outline: none;
    transform: translateY(50vh) translateY(-50%);
    width: 92vw;
    
    .modal-window {
      height: 100%;
      position: relative;

      .header {
        color: white;
        font-size: 13.5pt;
        font-weight: 300;
        background-color: @color-primary;
        height: 20px;
        word-wrap: break-word;

        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;

        .c-label {
          line-height: normal;
        }
      }

      .scrollarea {
        max-height: calc(~"80vh - 100px");
        padding: 0;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    .modal-window-mixed {
      width: 91.5vw;
    }
  }

  @media screen and (min-width: 1440px) {
    .modal-window-mixed {
      width: 78.5vw;
    }
  }

  @media screen and (min-width: 1600px) {
    .modal-window-mixed {
      width: 69vw;
    }
  }

  @media screen and (min-width: 1920px) {
    .modal-window-mixed {
      width: 61vw;
    }
  }
}

.modal-window-static {
  top: 2% !important;
  left: 2% !important;
  right: 2% !important;
  bottom: 2% !important;
  margin: 0 !important;
  overflow: visible !important;
  padding: 0 !important;
}

.modal-window-dynamic {
  top: 50% !important;
  left: 2% !important;
  right: 2% !important;
  bottom: auto !important;
  margin: 0 !important;
  max-height: 75% !important;
  overflow: visible !important;
  padding: 0 !important;
  -o-transform: translate(0, -50%) !important;
  -moz-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
}

.modal-window-static,
.modal-window-dynamic {
  border: 1px solid #ccc;
  border-radius: 0 !important;
  background: #fff;
  position: absolute;
  outline: none;
}

@media screen and (min-width: 1280px) {
  .modal-window-static {
    left: 8% !important;
    right: 8% !important;
  }

  .modal-window-dynamic {
    left: 8% !important;
    right: 8% !important;
  }
}

@media screen and (min-height: 800px) {
  .modal-window-static {
    top: 8% !important;
    bottom: 8% !important;
  }
}

@media screen and (min-width: 1440px) {
  .modal-window-static {
    left: 14% !important;
    right: 14% !important;
  }

  .modal-window-dynamic {
    left: 14% !important;
    right: 14% !important;
  }
}

@media screen and (min-height: 900px) {
  .modal-window-static {
    top: 14% !important;
    bottom: 14% !important;
  }
}

@media screen and (min-width: 1600px) {
  .modal-window-static {
    left: 19% !important;
    right: 19% !important;
  }

  .modal-window-dynamic {
    left: 19% !important;
    right: 19% !important;
  }
}

@media screen and (min-height: 1024px) {
  .modal-window-static {
    top: 19% !important;
    bottom: 19% !important;
  }
}

@media screen and (min-width: 1920px) {
  .modal-window-static {
    left: 22% !important;
    right: 22% !important;
  }

  .modal-window-dynamic {
    left: 22% !important;
    right: 22% !important;
  }
}

@media screen and (min-height: 1200px) {
  .modal-window-static {
    top: 22% !important;
    bottom: 22% !important;
  }
}

.modal-window-static .scrollarea {
  margin-bottom: 20px;
  margin-right: 25px;
}

.modal-window-dynamic .scrollarea .scrollarea-content,
.modal-window-static .scrollarea .scrollarea-content {
  padding: 0 5px 0 0;
}

.modal-window-dynamic .scrollarea .scrollarea-content .message,
.modal-window-static .scrollarea .scrollarea-content .message {
  margin: 2px;
}

.modal-window-dynamic .modal-window {
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.modal-window-static .modal-window {
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-window .header {
  padding: 20px;
}

.modal-window .header a {
  color: white;
  display: block;
  float: right;
  text-decoration: none;

  > .icon {
    display: block;
    font-size: 24px;
    margin-top: -2px;
  }
}

.modal-window .header a:first-of-type {
  margin-left: 30px;
}

.modal-window .header a + a {
  margin-right: 10px;
}

.modal-window .header a div {
  display: inline-block;
  font-size: 10pt;
  vertical-align: top;
  margin-top: 2px;
}

.modal-window .header + div {
  padding: 0 20px 20px 20px;
}
