@import (reference) "../../styles/less/colors.less";
@import (reference) "../../styles/less/legacy.less";

.data-integration .model-config .model-data {
  .inputs-group {
    display: flex;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    > label:extend(.default-grey-label all) {
    }

    > input {
      border: solid 1px lightgray;
      padding: 6px;
      outline: none;
    }

    > input.invalid-data {
      border-bottom: solid 2px @color-status-red;
      padding-bottom: 4px;
    }

    > span:extend(.single-line-content-on-light all) {
      color: @color-status-red;
    }

    > span.invisible {
      visibility: hidden;
    }

    [class^="input-"] {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
}
