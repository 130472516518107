.duval-pentagon-svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.duval-pentagon-container {
  position: relative;
  width: 100%;
  max-height: 35vh;//400px for fullHD
  margin: 3vh 0;//30px for fullHD

  svg {
    overflow: initial;
  }
}

.duval-pentagon-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
