.aging-contact-wear {
  &-chart {
    &__wrapper {
      height: calc(100vh - 630px);
      width: 100%;
    }
  }
  &__col {
    padding: 0;
  }
}
