@import (reference) "../../../../../styles/less/colors.less";
@import (reference) "../../../../../styles/less/variables.less";

.issue-details-comments-modal {
  padding: 0 !important;

  .scrollarea-content {
    padding: 20px;

    .comments {
      .comment + .comment {
        margin-top: 12px;
      }

      .comment {
        background-color: @color-gray-10;
        border: solid 1px @color-gray-10;
        padding: 12px;

        .comment-text {
          margin-top: 12px;

          > span {
            white-space: pre-line;
            word-break: break-word;
          }
        }
      }
    }
  }
}
