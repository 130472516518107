@import (reference) "../../../../../styles/less/colors.less";

@title-color: darken(@color-primary, 6.5%);

.route-not-found {
  display: flex;
  height: 100%;
  justify-content: center;

  .content {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 40px;

    .warning-icon > .icon {
      color: @color-status-orange;
      font-size: 250px;
      margin-right: 50px;
      opacity: 0.5;
      padding-top: 40px;
    }
    .title {
      font-size: 30px;
      font-weight: 600;
    }

    .message-bolded {
      font-weight: 600;
    }

    .not-found-container {
      margin: 30px 0 50px 0;
      div {
        align-items: center;
        font-size: 20px;
        justify-content: center;
      }
    }

    .homepage-button {
      display: flex;
      align-items: center;

      > span:first-child {
        margin-right: 8px;
      }
    }
  }
}
