.spinner {
  width: 150px;
  text-align: center;
}

.spinner.margin {
  margin: 50px auto;
}

.spinner > div {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 100%;
  display: inline-block;
  -o-animation: ahc-bouncedelay-animation 1.4s infinite ease-in-out both;
  -webkit-animation: ahc-bouncedelay-animation 1.4s infinite ease-in-out both;
  -moz-animation: ahc-bouncedelay-animation 1.4s infinite ease-in-out both;
  animation: ahc-bouncedelay-animation 1.4s infinite ease-in-out both;
}

.spinner.light div {
  background-color: #f2f2f2;
}

.spinner.dark div {
  background-color: #3c3c3c;
}

.spinner.small div {
  height: 10px;
  width: 10px;
}

.spinner.large div {
  height: 12px;
  width: 12px;
}

.spinner .bounce1 {
  -o-animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.spinner .bounce2 {
  -o-animation-delay: -0.64s;
  -webkit-animation-delay: -0.64s;
  -moz-animation-delay: -0.64s;
  animation-delay: -0.64s;
}

.spinner .bounce3 {
  -o-animation-delay: -0.48s;
  -webkit-animation-delay: -0.48s;
  -moz-animation-delay: -0.48s;
  animation-delay: -0.48s;
}

.spinner .bounce4 {
  -o-animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce5 {
  -o-animation-delay: -0.16s;
  -webkit-animation-delay: -0.16s;
  -moz-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner .bounce6 {
  -o-animation-delay: 0;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  animation-delay: 0;
}

@-webkit-keyframes ahc-bouncedelay-animation {
  0%,
  80%,
  100% {
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes ahc-bouncedelay-animation {
  0%,
  80%,
  100% {
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes ahc-bouncedelay-animation {
  0%,
  80%,
  100% {
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ahc-bouncedelay-animation {
  0%,
  80%,
  100% {
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
