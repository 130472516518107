.parameter-histogram-chart {
  display: flex;
  flex-direction: column;
  height: 100%;

  .resize-wrapper {
    flex-grow: 1;
  }

  .bb-axis-y-label {
    fill: black;
    font-weight: 600;
  }

  .bb-axis-x-label {
    fill: black;
    font-weight: 600;
  }

  .bb-ygrid {
    stroke: black;
    stroke-dasharray: 0;
    opacity: 0.4;
  }

  .bb-axis-x .tick {
    font-weight: normal;
  }

  .bb-axis-x .bb-axis-x-label {
    font-weight: normal;
  }

  .bb-axis-y .bb-axis-y-label {
    font-weight: normal;
  }
}
