@import (reference) "../../styles/less/legacy.less";

@single-line-content-font-size: 9pt;
@single-line-content-font-weight: 300;
@single-line-content-on-dark-color: #f1f1f1;

#single-line-content() {
  font-weight: @single-line-content-font-weight;
  font-size: @single-line-content-font-size;
}

.single-line-content-on-dark {
  color: @single-line-content-on-dark-color;
  #single-line-content;
}

.data-integration .models-panel {
  background-color: @color-primary;
  position: relative;

  > div + div {
    border-top: solid 1px @color-primary-dark;
  }

 

  .search {
    padding: 15px 15px;
    position: relative;

    > input:extend(.single-line-content-on-dark all) {
      background-color: @color-primary-dark;
      border: 1px solid @color-primary-dark;
      box-sizing: border-box;
      padding: 8px;
      padding-left: 30px;
      outline: none;
      width: 100%;
    }

    > span.icon {
      color: @color-primary;
      position: absolute;
      left: 23px;
      top: 23px;
      font-size: 16px;
    }
  }

  .add-new:extend(.single-line-content-on-dark all) {
    cursor: pointer;
    display: flex;
    padding: 15px 15px;
  }

  .scroll-wrapper {
    overflow: auto;
    height: 100%;
  }

  .scrollarea {
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    bottom: 0;

    .models-list:extend(.single-line-content-on-dark all) {
      > div.model {
        cursor: pointer;
        display: flex;
        padding: 10px 15px;
      }

      > div.model:hover,
      > div.model.selected {
        background-color: @color-primary-dark;
      }

      > div.no-data {
        padding: 10px 15px;
      }
    }
  }

  .add-new,
  .models-list {
    .model {
      position: relative;
    }

    .icon {
      font-size: 15px;
      margin-right: 5px;
    }

    .indicator {
      background-color: @color-primary;
      border: solid 1px @color-primary-light;
      border-radius: 50%;
      display: inline-block;
      height: 5px;
      position: absolute;
      top: 14px;
      right: 14px;
      width: 5px;
    }
  }
}
