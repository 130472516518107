@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/legacy.less";

.dashboard-reports {
  .navbar {
    background-color: @color-widget-background;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 54px;
    width: 100%;
    z-index: 1000;

    & + div {
      margin-top: 0;
    }

    .back {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;

      > .icon {
        margin: 0 15px;
      }
    }

    .report-name {
      margin-left: 15px;
    }

    .divider {
      background-color: @color-gray-100;
      height: 20px;
      width: 1px;
    }
  }

  .pbi-embedded-report {
    height: calc(~"100vh - 105px");
  }

  iframe {
    border: none;
  }
}
