.overlay-component {
  position: relative;
  pointer-events: none;

  .error-message:extend(.paragraph-grey) {
    font-size: 12pt;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .message-content-expanded {
      width: calc(66%);
      display: flex;
      justify-content: center;
    }

    .message-empty-expanded {
      width: calc(33%);
    }
  }

  .content {
    opacity: 0.2;
  }
}
