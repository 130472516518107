@import (reference) "../../../../styles/less/colors.less";

.modal-window-wide {
  width: 94vw !important;
}

.model-configuration-item {
  cursor: pointer;
  color: @color-primary !important;
  font-weight: 600;
  font-size: 9pt;
}

.model-configuration-item:hover {
  color: @color-primary-light !important;
}
