@import (reference) "../../../../styles/less/colors.less";

.notifications {
  > div:nth-child(2) {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;

    > span {
      padding-top: 40px;
    }

    .tables-container {
      margin-top: 40px;

      &__header {
        font-size: 14px;
      }

      div.tables {
        padding-top: 25px;
      }
    }
  }

  .grid {
    display: flex;
  }

  &__header-required {
    span:nth-child(2) {
      padding-left: 5px;
      color: red;
      font-weight: 600;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
  }

  .toggle-control.on .switch {
    background-color: @color-primary;
  }
}
