.button-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon {
    display: flex;
  }

  .icon {
    padding-right: 3px;
    padding-left: 3px;
  }

  .child {
    padding-right: 3px;
    padding-left: 3px;
  }
}
