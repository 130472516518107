@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";

.detail-page-side-panel {
  .asset,
  .component {
    border-top: solid 1px @color-gray-90;
    cursor: pointer;

    .details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: @spacing-xx-large;
      padding: @spacing-medium;

      .name-icon {
        display: flex;
        flex: 1;
        align-items: center;
        opacity: 0.33;

        .icon {
          margin-right: @spacing-large;
        }

        .name {
          color: @color-gray-0;
          word-break: break-all;

          &.no-icon {
            margin-left: @spacing-xx-large;
            margin-right: @spacing-large;
          }
        }
      }

      .risk {
        border-radius: 50%;
        height: @icon-font-size-base;
        min-height: @icon-font-size-base;
        width: @icon-font-size-base;
        min-width: @icon-font-size-base;
      }

      .risk.High {
        background-color: @color-risk-high;
      }

      .risk.Medium {
        background-color: @color-risk-medium;
      }

      .risk.Low {
        background-color: @color-risk-low;
      }

      .status {
        min-width: @spacing-xx-large;
        
        .icon {
          vertical-align: middle;
        }
      }

      &:hover {
        .name-icon {
          opacity: 1;
        }
      }
    }

    &.active > .details {
      .name-icon {
        opacity: 1;
      }
    }
  }

  .asset {
    border-top: none;
  }

  .error {
    background-color: @color-status-red;
    display: flex;
    align-items: center;
    padding: @spacing-medium;

    .icon {
      margin-right: @spacing-medium;
    }
  }
}
