@import (reference) "../../../../styles/less/variables.less";

@configuration-box-border-color: #ebebeb;
@configuration-box-header-color: #e4e4e4;
@configuration-box-organizations-color: #f5f5f5;
@configuration-box-font-color: #646464;

.configuration-box {
  min-width: 365px;
  width: 100%;
  border: solid 1px @configuration-box-border-color;

  .header {
    position: relative;
    padding: 5px;
    background-color: @configuration-box-header-color;
    justify-content: space-between;
    align-items: center;
    min-height: 25px;
    display: flex;
    font-size: @font-size-small;
    color: @configuration-box-font-color;

    .header-checkbox {
      span {
        padding-left: 10px;
      }
    }

    .header-name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;

      span:nth-child(2) {
        padding-left: 5px;
        color: red;
      }
    }
  }

  .organizations {
    font-size: @font-size-base;
    padding: 5px;
    background-color: @configuration-box-organizations-color;
  }
}
