@import (reference) "../../../../../styles/less/colors.less";

@header-cell-name-width: 41%;
@header-cell-date-width: 23%;
@header-cell-confidence-width: 13%;

.subfactors-list {
  padding: 1em 2em 1em 2em;
  overflow-y: hidden;

  .grid-custom.subfactors-grid {
    .grid-header,
    .grid-body {
      .grid-row {
        .grid-cell {
          &:not(:first-child) {
            border-left: solid 1px @color-gray-20;
          }
          &.name {
            width: @header-cell-name-width;
            min-width: @header-cell-name-width;
            max-width: @header-cell-name-width;

            &.parent {
              font-weight: 600;
            }
          }
          &.value {
            width: @header-cell-date-width;
            min-width: @header-cell-date-width;
            max-width: @header-cell-date-width;

            span.icon {
              color: @color-primary;

              &.Down {
                display: inline-block;
                transform: rotate(180deg);
              }

              &.None {
                display: none;
              }
            }
          }
          &.confidence {
            width: @header-cell-confidence-width;
            min-width: @header-cell-confidence-width;
            max-width: @header-cell-confidence-width;
          }

          span.date-value {
            padding-left: 0.5em;
          }
        }
      }
    }

    .grid-header:not(.safari) {
      padding-right: 17px;
    }

    .grid-body {
      max-height: calc(100vh - 330px);
      overflow-y: scroll;

      .grid-row {
        &:hover {
          cursor: inherit;
          background-color: inherit;
        }
        .grid-cell {
          &.name {
            background-color: @color-gray-10;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 0.5em 0 0 0;
    white-space: pre;
  }
}
