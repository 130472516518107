.pbi-report {
  .loading {
    min-height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      margin: unset;
    }
  }
}
