@import (reference) "../../styles/less/colors.less";

.tile.error {
  > .tile-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 150px;

    > img {
      height: 28px;
    }

    > .icon {
      color: @color-status-red;
    }

    > .error-message:extend(.paragraph-on-light) {
      margin-top: 10px;
    }
  }
}

.tile {
  background-color: white;
  border: solid 1px @color-gray-10;
  margin: 5px;

  > .tile-content {
    padding: 15px;

    .title {
      word-wrap: break-word;
    }
  }

  > .tile-content.processing,
  > .tile-content.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
  }

  > .tile-footer {
    border-top: solid 1px @color-gray-10;
    padding: 5px 15px;

    > .explore {
      display: flex;

      > span {
        margin: 5px 0px;
      }
    }
  }
}
