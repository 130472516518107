@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.aging-operations-widget {
  height: 100%;
  .bootstrap-row {
    height: 100%;
  }
  .aging-operations-chart {
    margin-top: 5px;
    &__title {
      font-size: @font-size-medium;
      line-height: 20px;
      color: @color-gray-90;
      font-weight: 600;
      padding: 0 10px;
    }
  }
}
