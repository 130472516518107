.detail-page-widgets.scroll-area {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;

  &.horizontal {
    overflow-x: auto;
  }

  .tab-widget {
    height: calc(100vh - 144px);
  }
}
