@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";

.homepage .analytics {
  .tile.analytics {
    .drop-down {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 60px;

      .analytics-icon {
        background-color: @color-status-yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 60px;
        width: 60px;
        min-width: 60px;

        > .icon {
          font-size: 48px;
        }
      }

      .analytics-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: @font-size-medium;
        height: 100%;
        padding: 0 15px 0 15px;
        width: 100%;

        > .icon {
          margin-left: 5px;
          transform: rotate(-90deg);
        }

        > .icon.top {
          transform: rotate(90deg);
        }
      }
    }

    .report-list {
      .c-label {
        color: @color-text-primary-on-light;
        font-weight: 600;
        font-size: 9pt;
        padding: 10px;
      }

      .reports {
        border-top: solid 1px @color-gray-10;
      }

      ul:extend(.paragraph-on-light) {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          a {
            color: black;
            display: block;
            padding: 7px 15px;
            text-decoration: none;
          }
        }

        li:hover {
          background-color: @color-gray-10;
        }
      }

      -moz-animation-name: ahc-pbi-tile-animation;
      -moz-animation-iteration-count: 1;
      -moz-animation-timing-function: ease-out;
      -moz-animation-duration: 0.3s;

      -webkit-animation-name: ahc-pbi-tile-animation;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-out;
      -webkit-animation-duration: 0.3s;

      animation-name: ahc-pbi-tile-animation;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
      animation-duration: 0.3s;

      .widget-message {
        padding: 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 0.5em;
          color: @color-primary;
        }
      }
    }

    .analytics-url:extend(.paragraph-on-light) {
      border-top: solid 1px @color-gray-10;
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        font-size: 20px;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  .tile.processing {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
}

@-moz-keyframes ahc-pbi-tile-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ahc-pbi-tile-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ahc-pbi-tile-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
