@import (reference) "../../styles/less/colors.less";

.common-no-data-message {
  color: @color-gray-90;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.with-border {
    border: dashed 2px @color-gray-10;
    border-radius: 1px;
  }
}
