@import (reference) "../../../../../styles/less/colors.less";

.details-page-issues {
  padding: 12px;

  .create-issue {
    padding: 0;
    position: absolute;
    right: 54px;
    top: 42px;

    > span {
      top: 0;
      left: 0;
    }

    .icon {
      font-size: 28px;
    }
  }

  .issue-type-switch {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .scrollarea {
    margin: 0 -12px;
    padding: 0 12px;
    position: absolute;
    bottom: 0;
    top: 160px;
    left: 12px;
    right: 12px;
    overflow-y: auto;
    scroll-behavior: smooth;

    .list {
      padding: 10px 0;
    }
  }

  .show-more-container {
    padding: 4px;
    display: flex;
    justify-content: flex-end;

    button {
      color: @color-primary;
    }
  }
}
