@import (reference) "../../styles/less/legacy.less";
@import (reference) "../../styles/less/variables.less";
@import (reference) "../../styles/less/colors.less";

.modal-window-mixed.xlsx-downloader-modal {
  width: 340px;
  .asset-attributes-modal-content {
    .labeled-row .label:extend(.default-grey-label) {
      text-transform: uppercase;
    }

    .labeled-row .value:extend(.paragraph-on-light) {
      margin-left: 12px;
    }
  }
}

.data-integration .model-config {
  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  flex-grow: 1;
  width: 1px;

  form {
    height: 100%;
  }

  .scroll-wrapper {
    height: calc(~"100% - 100px");
    overflow: auto;
  }

  .scrollarea {
    height: calc(~"100% - 100px");
  }

  .container {
    margin: 0 5px;
  }

  .model-config-header {
    height: 65px;
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    h4 {
      margin-bottom: 0;
      margin-right: @spacing-x-large;
    }

    h5 {
      color: @color-gray-70;
      margin-bottom: @spacing-x-large;
    }

    .header-buttons {
      display: flex;

      > div {
        display: flex;
        padding: 0 5px;
      }

      > div + div {
        border-left: solid 1px #acacac;
      }

      > div:last-of-type {
        padding-right: 0;
      }

      div.button {
        padding: 5px;
        position: relative;
      }

      button {
        min-width: 100px !important;
      }

      button + input {
        margin-left: 10px;
      }
    }

    .model-config-header-name {
      display: flex;
      flex-direction: row;
      color: gray;
      align-items: center;
    }
  }

  .model-config-footer {
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    height: 35px;
    padding-left: 20px;
  }
}
